.footer {
    width: 100%;
    .footer-container {
        .content {
            display: flex;
            flex-direction: column;
            .logo {
                width: 128px;
                opacity: 0.7;
                a {
                    display: block;
                    width: 100%;
                    img {
                        width: 100%;
                    }
                }
            }
            .info {
                padding: 18px 0;
                .info-list {
                    .info {
                        padding: 4px 0;
                        color: $cl-gray;
                    }
                }
            }
            .copyright-wrap {
                .copyright {
                    color: $cl-gray;
                }
            }
        }
    }
}