.video-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    display: none;
    background: rgba(0, 0, 0, 0.8);
    .video-content {
        display: flex;
        justify-content: center;
        gap: 4%;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        padding: 128px 72px;
        @include mobile {
            padding: 48px 12px;
        }
        .copy-title {
            color: $cl-white;
        }
        .copy-sub-heading {
            color: $cl-lg-gray;
        }
        .expanded-video {
            display: block;
            width: 100%;
            height: 100%;
            padding: 0 12px;
            @include mobile {
                width: 100%;
            }
        }
        .close-btn {
            position: absolute;
            top: 48px;
            right: 72px;
            cursor: pointer;
            @include mobile {
                top: 18px;
                right: 12px;
            }
        }
    }
}

.show-video-overlay {
    display: block;
}