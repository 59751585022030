.page-home {
    position: relative;
    .page-container {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100vh;
        .content {
            display: flex;
            align-items: center;
            max-width: 50%;
            @include mobile {
                max-width: 100%;
            }
            .copy-title {
                margin-bottom: 48px;
                color: $cl-lg-gray;
                line-height: 72px;
                @include mobile {
                    font-size: 18px;
                    line-height: 24px;
                    text-align: center;
                }
        
                span {
                    font-weight: bold;
                    color: $cl-main;
                }
            }    
        }
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
    }
    
    .background-video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -10;
        object-fit: cover;
    }
}